import React from 'react'
import '../css/GlobalTruck.css';
import install from '../img/globaltrack/install.svg';
import setting from '../img/globaltrack/setting.svg';
import wifi from '../img/globaltrack/wifi.svg';
import book from '../img/globaltrack/book.svg';

const GlobalTruck = () => {
    return(
        <>
        <div className="globaltrack">
            <div className="wrapper">
                <div className="globaltrack__container">
                    <h3>Как работает Global Track</h3>
                    <div className="globaltrack__content">
                        <div className="globaltrack__content-block">
                            <img src={install} alt=''/>
                            <p>Установим оборудование</p>
                        </div>
                        <div className="globaltrack__content-block" id='scroll-two'>
                            <img src={setting} alt='' />
                            <p>Проведем настройку</p>
                        </div>
                        <div className="globaltrack__content-block" >
                            <img src={wifi} alt=''/>
                            <p>Подключим к системе мониторинга</p>
                        </div>
                        <div className="globaltrack__content-block">
                            <img src={book} alt=''/>
                            <p>Обучим работе системы</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </>
    )
}

export default GlobalTruck;
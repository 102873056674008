import React from "react";
import "../css/Navbar.css";
import logo from "../img/navbar/logo.png";
import ModalForm from "../components/ModalForm.jsx";
import { Link} from 'react-scroll'
import MobileNav from "./MobileNav";

const Navbar = () => {
  const [modalActive, setModalActive] = React.useState(false)
    const handleClick = (e) =>{
        e.preventDefault();
        setModalActive(true)
    }

  return (
    <>
      <nav className="navbar">
        <div className="wrapper">
          <div className="navbar-container">
            <img src={logo} alt="" />
            <div className="navbar-links">
              <Link to="scroll-one" smooth={true}>Как это работает</Link>
              <Link to="eco" smooth={true}>Расчёт экономии</Link>
            </div>
            <div className="navbar-contacts">
              <a href="tel:+78352236049" className="navbar-number">
              +7  835 223 60 49
              </a>
              <a href="/" className="navbar-btn" onClick={(e) => handleClick(e)}>
                Перезвоните мне
              </a>
            </div>
          </div>
        </div>
      </nav>
      <MobileNav/>
      <ModalForm active={modalActive} setActive={setModalActive} header='Заказать обратный звонок'/>
    </>
  );
};

export default Navbar;

import React from "react";
import { Link } from "react-scroll";
import styled from "styled-components";
import ModalForm from "../components/ModalForm.jsx";
import "../css/Burger.css";

function Burger(props) {
    const [modalActive, setModalActive] = React.useState(false)
    const handleClick = (e) =>{
        e.preventDefault();
        setModalActive(true)
    }
   const BurgerMenu = styled.div`
      display: ${({ open }) => (open ? "inherit" : "none")};
      position: sticky;
      top: 89px;
      z-index: 100;
   `;
   return (
       <>
      <BurgerMenu open={props.open}>
         <div className="burger">
            <div className="wrapper">
               <div className="burger-container">
                  <Link to="scroll-one" smooth={true} onClick={props.changeState}>
                     Как это работает
                  </Link>
                  <Link to="scroll-two" smooth={true} onClick={props.changeState}>
                     Расчёт экономии
                  </Link>
                  <a href="tel:+78352236049" className="navbar__number">
                  +7 (835) 223-60-49
                  </a>
                  <a href="/" className="mob-navbar-btn" onClick={(e) => handleClick(e)}>
                Перезвоните мне
              </a>
               </div>
            </div>
         </div>
      </BurgerMenu>
      <ModalForm active={modalActive} setActive={setModalActive} header='Заказать обратный звонок'/>
      </>
      
   );
}

export default Burger;

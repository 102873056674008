import React from 'react';
import '../css/Eco.css';
import pic1 from '../img/eco/pic1.png';
import pic2 from '../img/eco/pic2.png';
import rub from '../img/eco/rub.svg';
const Eco = () => {
    return(

        <>
            <div className="eco" id='eco'>
                <div className="wrapper">
                    <div className="eco__container">
                        <h3>Экономия на системе</h3>
                        <div className="eco__container-img">
                        <img src={pic1} alt=''/>
                        <img src={pic2} alt=''/>
                        </div>
                        <div className="eco__rub">
                            <img src={rub} alt=''/>
                            <p>Только с <span>1 транспортной единицы за месяц</span><br/>
                            система Антиплатон позволяет<br/>
                            экономить <span>более 15 000 рублей</span></p>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Eco;
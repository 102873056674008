import './App.css';
import Main from './components/Main';
import Navbar from './components/Navbar';
import MoneySave from './components/MoneySave';
import Others from './components/Others';
import Howitworks from './components/Howitworks';
import GlobalTruck from './components/GlobalTruck';
import Eco from './components/Eco';
import Footer from './components/Footer';


function App() {
  return (
    <>
      <Navbar/>
      <Main/>
      <MoneySave/>
      <Others/>
      <Howitworks/>
      <GlobalTruck/>
      <Eco/>
      <Footer/>
    </>
  );
}

export default App;

import React, {useState} from 'react';
import '../css/ModalForm.css';
import close from '../img/gg_close.svg';

const ModalForm = ({active, setActive, header}) => {
    const [name, setName] = useState('')
    const [phone, setPhone] = useState('')
    return (
        <>
            <div className={active ? "modalform active" : "modalform"} onClick={() => setActive(false)}>
                <div className={active ? "modalform__container active" : "modalform__container"}
                     onClick={(e) => e.stopPropagation()}>
                    <img src={close} alt='' onClick={() => setActive(false)}/>
                    <h4>{header}</h4>
                    <p>Оставьте ваши контакты и наш специалист свяжется с вами в ближайшее время</p>
                    <form name='google-sheet' action="https://formsubmit.co/sale7@dskazan.ru" method="POST">
                        <input type="text" placeholder='Ваше имя' onChange={e => setName(e.target.value)} value={name} name='Имя'
                               required/>
                        <input type="tel" placeholder='Номер телефона' onChange={e => setPhone(e.target.value)}
                               value={phone} name='Телефон'required/>
                               <input type="hidden" name="_next" value="http://www.antiplaton.ru"/>
                               <input type="hidden" name="_captcha" value="false"/>
                               <input type="hidden" name="_cc" value="marketingyarar@gmail.com"/>
                               <input type="hidden" name="_cc" value="lenar@dskazan.ru"/>
                        <button type="submit" onClick={() => setActive(false)}>Отправить</button>
                    </form>
                    <p><span>Нажимая на кнопку, вы даете согласие на обработку своих персональных данных и соглашаетесь с <a
                        href="/#" className="sogl">Пользовательским соглашением</a></span>
                    </p>
                </div>
            </div>
        </>
    )
}

export default ModalForm;
import React from 'react'
import '../css/Footer.css';
import logo from '../img/footer/logo.png';
import { Link} from 'react-scroll'
const Footer = () => {
    
    return (
        <>
        <footer className="footer">
        <div className="wrapper">
          <div className="footer-container">
            <img src={logo} alt="" />
            <div className="footer-links">
            <Link to="howitworks" smooth={true}><a href="/#">Как это работает</a></Link>
            <Link to="howitworks" smooth={true}><a href="/#">Расчёт экономии</a></Link>
            </div>
            <div className="footer-contacts">
              <a href="tel:+78352236049" className="footer-number">
              +7 835 223 60 49
              </a>
              <a href="/#" className="footer-btn">
                Перезвоните мне
              </a>
            </div>
          </div>
        </div>
        </footer>
        </>
    )
}

export default Footer;
import React from "react";
import "../css/Others.css";
import pic from "../img/others/pic1.png";
import pic2 from "../img/others/pic2.png";
const Others = () => {
  return (
    <>
      <div className="others">
        <div className="wrapper">
          <div className="others__container">
            <div className="content-block">
              <h3>Что сейчас используют в других системах:</h3>
              <div className="content-block__bottom">
                <img src={pic} alt={""} />
                <p>
                  Глушилки, которые блокируют GPS сигнал со спутника, лишив тем
                  самым возможности собирать бортовым устройством данные о
                  пройденных вами километрах. Проходя под рамкой Платон, система
                  получает данные с бортового устройства о том, что пройдено 0
                  км, и соответственно списывает с вашего лицевого счета 0 руб.
                </p>
              </div>
            </div>

            <div className="content-block" id='scroll-one'>
              <h3>В чём проблема такого подхода?</h3>
              <div className="content-block__bottom">
                <img className="content__img" src={pic2} alt={""} />
                <p className="content__p">
                  Глушилка полностью подавляет сигнал GPS, соответственно не
                  будет работать система мониторинга, которая для большинства
                  клиентов является основой всех бизнес процессов, связанных с
                  автопарком.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Others;

import React from "react";
import "../css/Main.css";
import mainImg from "../img/main/mainPic.png";
import ModalForm from "../components/ModalForm.jsx";
const Main = () => {
   const [modalActive, setModalActive] = React.useState(false)
    const handleClick = (e) =>{
        e.preventDefault();
        setModalActive(true)
    }
   return (
      <>
         <div className="main">
            <div className="wrapper">
               <div className="main__content-conatiner">
                  <div className="text-container">
                     <h1 className="main__header">
                        Система Антиплатон позволяет экономить на Платон, без
                        глушения ГЛОНАСС мониторинга транспорта
                     </h1>
                     <h3>
                        Экономьте на платежах за Платон, сохраняя контроль за
                        водителями
                     </h3>
                     <button className="main__btn" onClick={(e) => handleClick(e)}>Заказать</button>
                  </div>
                  <img src={mainImg} alt=""/>
               </div>
            </div>
         </div>
         <ModalForm active={modalActive} setActive={setModalActive} header='Заказать'/>
      </>
   );
};

export default Main;

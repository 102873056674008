import React, {useState} from 'react';
import logo from "../img/navbar/logo.png";
import burger from "../img/burger.png";
import close from "../img/close.png";
import styled from 'styled-components';
import Burger from '../components/Burger';
import '../css/MobileNav.css';

const Change = styled.button`
 
  background: ${({open}) => open ? `url(${close})` : `url(${burger})`} no-repeat;
  
  border: none;
  
  background-color: transparent;
`
const MobileNav = () => {

    const [open, setOpen] = useState(false)
    return (
        <>
            <div className="mobile-nav">
                <div className="wrapper">
                    <div className="container">
                        <img src={logo} className="navbar__logo" alt=""/>
                        <Change open={open} onClick={() => setOpen(!open)}>
                            <button className="burger-icon" ></button>
                        </Change>

                    </div>
                </div>
            </div>
            <Burger open={open}
            changeState={open => setOpen(!open)}/>
        </>
    )
}
export default MobileNav;
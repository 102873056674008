import React from "react";
import "../css/Howitworks.css";
import check from '../img/howitworks/checked.svg';
import pic1 from '../img/howitworks/pic1.png';
import pic2 from '../img/howitworks/pic2.png';

const Howitworks = () => {
  return (
    <>
      <div className="howitworks" id='howitworks'>
        <div className="wrapper">
          <div className="howitworks__container">
            <h3>Как работает система Антиплатон</h3>
            <div className="howitworks__content">
              <div className="howitworks__text-block">
                <p>
                  Специально разработанная система Антиплатон благодаря
                  переработанному алгоритму работы перекрывает канал связи GPS
                  Платон. Частью системы является тумблер (переключатель),
                  который выводится в кабину водителя. Тумблер позволяет
                  водителям легко и быстро выключать перед рамками Платон
                  систему и не попадать под штрафы. Режим работы системы
                  Антиплатон (вкл/выкл) можно отслеживать в системе мониторинга.
                </p>
                <div className="client-benefits">
                    <h4>Что получает клиент:</h4>
                    <div className="benefit">
                        <img src={check} alt=""/>
                        <p>Экономию на платежах Платон до 90% ГЛОНАСС</p>
                    </div>
                    <div className="benefit">
                        <img src={check} alt=""/>
                        <p>Возможность использования полного функционала системы мониторинга</p>
                    </div>
                </div>
              </div>
              <div className="howitworks__img-block">
                <img src={pic1} alt=""/>
                <img src={pic2} alt=""/>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Howitworks;

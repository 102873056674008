import React from "react";
import pic from "../img/anti/platonLogo.png";
import "../css/MoneySave.css";

const MoneySave = () => {
  return (
    <>
      <div className="moneysave">
        <div className="wrapper">
          <div className="moneysave__container">
            <img src={pic} alt="" />
            <div className="moneysave__text">
              <h3>
                Как можно экономить на дополнительных расходах при
                грузоперевозках
              </h3>
              <p>
                Компания «Глобал Трек» внимательно относится к запросам своих
                клиентов, поэтому нами было разработано уникальное для нашего
                региона решение, которое прошло все испытания и является на 100%
                рабочим – «Антиплатон».
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default MoneySave;
